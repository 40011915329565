import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMobileAlt,faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons'


const phone = <FontAwesomeIcon icon={faMobileAlt} />
const email = <FontAwesomeIcon icon={faEnvelope} />
export default function Footer() {
  return (
    <div className="footerdiv greybg" >
      <div className="container">
          <div className="row">
              <div className="col-md-6">
                <p style={{marginBottom:'0px'}}>© 2022 MENAPAC | ALL RIGHTS RESERVED.</p>
              </div>
              <div className="col-md-6" style={{textAlign: "right"}}>
                <p style={{marginBottom:'0px'}}>{phone}<a style={{textDecoration:'none',color:'#000'}} href="tel:+971581046943">&nbsp;+971581046943</a>  &nbsp;&nbsp; {email}<a style={{textDecoration:'none',color:'#000'}} href="mailto:info@menapac.com">&nbsp;info@menapac.com</a> </p>

              </div>
          </div>
      </div>
    
    </div>
  )
}
