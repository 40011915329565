import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import '../css/Style.css'

import 'bootstrap/dist/css/bootstrap.min.css';

// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';


import logo from '../img/logo1.png'

export default function Header() {
  const [menudisp, setmenudisp] = useState(false)
function openMenu(val){
  setmenudisp(true)
}
  return (
    
    <div>


  
    <div className="container-fluid sticky-top bg-white shadow-sm" style={{background:'f7f7f7 !important'}}>
      <div className="container">
   
        <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0" style={{background:'f7f7f7 !important'}}>
          <Link to="/" className="navbar-brand" style={{width:'37%'}}>
            {/* <h1 className="m-0 text-uppercase text-primary"><i className="fa fa-clinic-medical me-2" />Menapac</h1> */}
          <img src={logo} className="mainlogo" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse"  id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
             
              <Link to="/about-us" className="nav-item nav-link">About Us</Link>
             
              <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products</a>
                <div className="dropdown-menu m-0">
                  <Link to="/products/pvc" className="dropdown-item">PVC</Link>
                  <Link to="/products/pvdc" className="dropdown-item">PVDC</Link>
                  <Link to="/products/aluminium" className="dropdown-item">Aluminium Tube</Link>
                  <Link to="/products/caps" className="dropdown-item">Caps</Link>
                  <Link to="/products/api" className="dropdown-item">API</Link>
                  <Link to="/products/excipients" className="dropdown-item">Excipients</Link>
                </div>
              </div>
             
             
              <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</a>
                <div className="dropdown-menu m-0">
                <Link to="/services/sourcing" className="dropdown-item">Sourcing</Link>
                  <Link to="/services/medical" className="dropdown-item">Medical Service</Link>
                  <Link to="/services/customise-packaging-solution" className="dropdown-item">Customise Packaging Solution</Link>
                  <Link to="/services/formulation" className="dropdown-item">Formulation</Link>
                  <Link to="/services/shipping-and-logistics" className="dropdown-item">Shipping & Logistics</Link>
                
                </div>
              </div>
              <Link to='/sonoco' className="nav-item nav-link">Sonoco</Link>
              <Link to='/contact-us' className="nav-item nav-link">Contact Us</Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>

  )
}
