//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner from '../../img/banners/8.jfif'
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-bootstrap';

import pvc1 from '../../img/products/pvc.jpg'
import pvc2 from '../../img/products/pvdc.PNG'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons'

const circlecheck = <FontAwesomeIcon icon={faCircleCheck} />
export default function PVC() {
  
    const [type, settype] = useState('product')
function settabtype(val){
    settype(val)
}
  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | PVC</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >PVC</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">

            <div className='row'>
                <div className="col-md-5" style={{paddingLeft:'0px'}}>
                <Carousel>
   
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={pvc1}
        alt="Second slide"
        />
        <Carousel.Caption>
        
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100"
        src={pvc2}
        alt="Second slide"
        
        />
        <Carousel.Caption>
       
        </Carousel.Caption>
    </Carousel.Item>
    
   
</Carousel>
              
                </div>
              
 <div className="col-md-7 greybg tabsec" style={{paddingRight:'0px'}}>
 <ul className="nav nav-pills nav-justified">
        <li className="nav-item producttab" style={{
          backgroundColor: type=='product' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link " style={{
          color: type=='product' ? '#fff' : '#000'
        }} aria-current="page" onClick={()=>settabtype('product')} >Product Description</a>
        </li>
        <li className="nav-item producttab" style={{
          backgroundColor: type=='spec' ? '#000096' : '#e6e6e6'
        }}>
          <a className="nav-link" style={{
          color: type=='spec' ? '#fff' : '#000'
        }} onClick={()=>settabtype('spec')}>Specification</a>
        </li>
   
      </ul>
      <div className="product_desc">
      {type=='product'?(
 <div className="row">
 <p>In tropical regions, the hygroscopic sensitive products packaging need to be of high quality. PVdC coated PVC films distributed by MENA-PAC are considered as best for the blister packaging due to the coating over rigid PVC films. The PVdC coating of optimum thickness on one side of the packaging minimizes the gas and moisture permeability.</p>
<p>The thickness of PVC ranges from 200 to 350 microns with coating GSM 20, 40, 60,90 and 120 GSM to suit the different pharma and food packaging requirements. Also, PVdC coated PVC film is produced as per current GMP norms and in compliance with pharmaceutical packaging regulations.</p>

<h5>Triplex – PVC /PE/PVDC</h5>
<p>Some sensitive or hygroscopic pharmaceutical products need a high level of protection against moisture and oxygen where conventional PVC or PVDC films won’t work. That’s where triplex (PVC/PE/PVDC), the best blister film for medicines packaging is more favourable.</p>
<p>MENA-PAC triplex film is manufactured by laminating PVC film with PE layer followed by PVDC coating on it, where all the operations like- calendaring, lamination and coating are done under one roof in a controlled environment and in an accordance to national and international standards.</p>

 <p>Our PVC products are suitable for different types of sterilization:</p>
 <div className="col-md-12">
 <ul  style={{listStyle:'circle'}}>
  <li>ETO</li> 
  <li>Steam</li>
  <li>Gamma rays</li>
  <li>Beta rays</li>
 </ul>
 </div>
 

 
 </div>

      ):null}
      
      {type=='spec'?(
        <div className="row">
        <p>Material: PVC
0.25mm,0.35mm or customized
Transparent, Glass clear,Clear with blue tint, Amber, Orange, White, Blue, Brown, Pink, Green, Yellow or Customized
</p>
<p>Kind: Transparent Plastic Sheet</p>
<p>Hardness: Rigid</p>
<p>Density: 1.37g/cm3</p>
       </div>
      ):null}
        </div>
                </div>

            </div>

            
        
            <div style={{display:"inline-block"}}></div>
        </div>
      
        </section>
  )
}
