//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner4 from '../../img/banner4.jfif'
import banner from '../../img/banners/11.jfif'
import load from '../../img/load.gif'
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCar, faPhone,faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

const phone = <FontAwesomeIcon icon={faPhone} />
const email = <FontAwesomeIcon icon={faEnvelope} />
const loc = <FontAwesomeIcon icon={faLocationDot} />
export default function Contact() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loadingshow, setloadingshow] = useState(false)
  const [resultmsg, setresultmsg] = useState('')
const [formdata, setformdata] = useState({
  name:'',
  email:'',
  phone:'',
  message:''
})
 //const API_PATH=`${SERVER_URL}customs_enquiry.php`;
 const API_PATH='http://menapac.com/modal/contact_email.php';
function handleFormSubmit(event){
  event.preventDefault();
  console.log(formdata);
  if(formdata.name!="" && formdata.email!="" ){

  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formdata.email))
  {
      
    setloadingshow(true)
  axios({
      method: 'post',
      url: `${API_PATH}`,
      data: formdata
    })
    .then((res)=>{
      setloadingshow(false)
        //setresultmsg(res.data.sent)
        console.log(res.data.message)
        setresultmsg(res.data.message);
        //console.log(resultmsg)
    })
      // .then(res => {
      //   this.setState({
      //     mailSent: res.data.sent
      //   })
      // })
      .catch(error => console.log(error.message));
  }else{
      setresultmsg('Please enter a valid email address');
  }
}else{
  setresultmsg('Please enter all required fields');
}
}
  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | Contact</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d' ,backgroundPosition:'center'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >Contact us</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">

            <div className='row'>
            <div className="container">
        <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
          <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Any Questions?</h5>
          <h1 className="display-4">Please Feel Free To Contact Us</h1>
        </div>
        <div className="row g-5 mb-5">
          <div className="col-lg-4">
            <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
              <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width: '100px', height: '70px', transform: 'rotate(-15deg)'}}>
                <i className="fa fa-2x fa-location-arrow text-white" style={{transform: 'rotate(15deg)'}}>{loc}</i>
              </div>
              <h6 className="mb-0">25 61 A Street, Garhoud, Dubai, AE</h6>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
              <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width: '100px', height: '70px', transform: 'rotate(-15deg)'}}>
              <i className="fa fa-2x fa-location-arrow text-white" style={{transform: 'rotate(15deg)'}}>{phone}</i>
              </div>
              <h6 className="mb-0">+971 58 104 6943</h6>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
              <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width: '100px', height: '70px', transform: 'rotate(-15deg)'}}>
              <i className="fa fa-2x fa-location-arrow text-white" style={{transform: 'rotate(15deg)'}}>{email}</i>
              </div>
              <h6 className="mb-0">info@menapac.com</h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{height: '500px'}}>
            <div className="position-relative h-100">
              <iframe className="position-relative w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14434.23836541308!2d55.3414944!3d25.2517545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9b75191fc012bc!2sTime%20Express%20Parcels%20LLC!5e0!3m2!1sen!2sae!4v1666850742213!5m2!1sen!2sae"  style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center position-relative" style={{marginTop: '-200px', zIndex: 1}}>
          <div className="col-lg-8">
            <div className="bg-white rounded p-5 m-5 mb-0">
            <form>
            <div className="row g-3">
              <div className="col-12 col-sm-6">
                <input type="text" className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,name:e.target.value})} placeholder="Your Name" style={{height: '55px'}} />
              </div>
              <div className="col-12 col-sm-6">
                <input type="email" className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,email:e.target.value})} placeholder="Your Email" style={{height: '55px'}} />
              </div>
              <div className="col-12">
                <input type="text" className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,phone:e.target.value})}  placeholder="Contact Number" style={{height: '55px'}} />
              </div>
              <div className="col-12">
                <textarea className="form-control bg-light border-0" onChange={(e)=>setformdata({...formdata,message:e.target.value})} rows={5} placeholder="Message" defaultValue={""} />
              </div>
              <div className="col-12">
                <button className="btn btn-primary w-100 py-3 menabtn" onClick={e => handleFormSubmit(e)} type="submit">Send Message</button>
              </div>
                  {loadingshow?(

                    <img src={load} style={{width:'10%'}} />
                  ): null}          
      <p>{resultmsg}</p>
            </div>
          </form>
            </div>
          </div>
        </div>
      </div>


            </div>

      
        
            <div style={{display:"inline-block"}}></div>
        </div>
      
        </section>
  )
}
