//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../../css/Sonoco.css';
import banner4 from '../../img/banner4.jfif'
import banner from '../../img/about6.jfif'
import r1 from '../../img/r11.jfif'
import r2 from '../../img/r2.jfif'
import r3 from '../../img/r3.jfif'
import r4 from '../../img/r4.jfif'
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-bootstrap';
import about from '../../img/about4.jfif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircle, faCircleCheck, faPlay } from '@fortawesome/free-solid-svg-icons'
const more = <FontAwesomeIcon icon={faCircleCheck} style={{fontSize:'10px',color:'#3685de'}} />
export default function Aboutus() {
    
    function FadeInSection(props) {
        const [isVisible, setVisible] = React.useState(false);
        const domRef = React.useRef();
        React.useEffect(() => {
          const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
          });
          observer.observe(domRef.current);
        }, []);
        return (
          <div
            className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
          >
            {props.children}
          </div>
        );
      }
      function FadeInSection2(props) {
        const [isVisible, setVisible] = React.useState(false);
        const domRef = React.useRef();
        React.useEffect(() => {
          const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
          });
          observer.observe(domRef.current);
        }, []);
        return (
          <div
            className={`fade-in-section2 ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
          >
            {props.children}
          </div>
        );
      }

  return (

    <section id="cd-intro">
<Helmet>
        <title>MenaPac | About Us</title>
      </Helmet>
        <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 your_business text-center">
                        <div className="business_content">
                       
                            <h1 className="bannertitle" >About Us</h1>
                            {/* <p>Search for answers or explore regular help topics below</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="container help mt-5 maincontent">
            <div className="row" > 
                <div className="col-md-5 aboutbackground" style={{ backgroundImage: `url(${about})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',minHeight:'615px' }}>
               
                </div>
     

                <div className="col-md-7" style={{paddingLeft:'4%'}}>
               
                    <div className="row">
                    <FadeInSection id="1">
                        <div className="col-md-12">
                            <h3>ABOUT US</h3>
                            <p>MENAPAC is a leading distributor of pharmaceutical packaging API & Excipients. We have been providing top-quality products, custom-tailored medical packaging solutions, and outstanding service to customers worldwide. At MENAPAC, we are proud to meet the needs of medication packagers in a variety of market segments, from Pharmaceuticals manufacturing to long-term care facilities, hospitals, and many others.</p>
                        </div>
                        </FadeInSection>
                        <FadeInSection id="2">
                        <div className="col-md-12">
                            <h3>WHY WE ARE DIFFERENT</h3>
                            <p>We pride ourselves on providing a work environment where our employees can meet their potential and as a team strive to be the industry standard in service to customers. We maintain a superior level of integrity with interactions in every aspect of our business and conduct our business as model corporate citizens. As a result, we appreciate and are proud of our achieved success.</p>
                       <p>MENAPAC is a manufacturer’s representative, importer and distributor, the company has been successfully supplying the GCC, MENA & African pharmaceutical, nutraceutical, healthcare and animal feed industry. We also supply specialized vitamins. </p>
                       <p>MENAPAC primarily supply bulk raw ingredients along with various specialised printed and unprinted pharmaceutical packaging products,
our list of products includes the following:
</p>
                        </div>
                        </FadeInSection>
                    </div>
                   
                </div>
           
            </div>
            <FadeInSection id="1">
            <div className="row" style={{marginTop:'5%'}}>
                <table className="table table-bordered">
                 <thead>
                  <tr>
                    <th>RAW INGREDIENTS</th>
                    <th>PHARMACEUTICAL PACKAGING PRODUCTS</th>
                  </tr>
                 </thead>
                 <tbody>
                    <tr>
                        <td>Pharmaceutical and Veterinary API’s</td>
                        <td>Glass Bottles, Jars and Droppers</td>
                    </tr>
                    <tr>
                        <td>Colourants and Pigments</td>
                        <td>Securitainers (Plastic/Printed/Unprinted)</td>
                    </tr>
                    <tr>
                        <td>Amino Acids</td>
                        <td>Collapsible Tubes (Plastic/Laminate/Alluminium)</td>
                    </tr>
                    <tr>
                        <td>Colourants and Pigments</td>
                        <td>Empty Gelatin Capsules (Printed/Unprinted)</td>
                    </tr>
                    <tr>
                        <td>Vitamins</td>
                        <td>Aluminium and Plastic Bottles (Printed/Unprinted)</td>
                    </tr>
                    <tr>
                        <td>Custom Vitamin Premixes</td>
                        <td>Closures and Speciality Dispensers</td>
                    </tr>
                    <tr>
                        <td>Specialised Pharma Excipients</td>
                        <td>Laminate, Foils and Sachets (Printed/Unprinted)</td>
                    </tr>
                   
            
                 </tbody>
                </table>
            </div>
            </FadeInSection>
     
<div className="row" style={{marginTop:'2%'}}>
<FadeInSection id="2">
    <h5>Delivering Complete Unit Dose Packaging Solutions</h5>
    <h6>Quality Pharmaceutical Packaging Systems</h6>
    <p>MENAPAC has grown to become one of the world’s leading unit dose pharmaceutical packaging & chemical companies. We proudly produce high-quality pharmaceutical packaging, consumable materials, and our exclusive Sonoco product range with our Chill-Therm and Chill-Tech award winning products. We serve customers throughout North America, Latin and South America, Europe, the Middle East, and in emerging markets internationally.</p>
<p>Our mission is to be the premier distributor of pharmacy packaging solutions that contribute to saving patients’ lives while increasing efficiency and profitability for all customers</p>
</FadeInSection>
<FadeInSection id="3">
<h5>Our Guiding Principles</h5>
<p>At MENAPAC, we strive to apply the highest standards of excellence in developing our pharmaceutical packaging systems, software, and MENAPAC-certified consumable materials. We offer high-quality products at competitive prices, and our equipment delivers the most current features and functionality while maintaining maximum reliability and ease of use. All of our customers receive outstanding customer service and technical support in a timely, respectful, and courteous manner. Based on a foundation of professionalism and enthusiasm, we develop, maintain, and foster ongoing relationships with our customers, strategic partners, and distributors alike.</p>
<p>To ensure continued adherence with industry regulations and compliance with government requirements for unit dose medication packaging, we are constantly improving our pharmaceutical packaging systems. We also promote constant learning and development among all employees to ensure we are able to remain a leader in our ever-evolving markets.</p>
</FadeInSection>

<h6>We offer a full range of packaging & Chemical products such as:</h6>
<div className="col-md-6">
<FadeInSection id="4">
<ul style={{listStyle:'none'}}>
    <li>{more} PVC</li>
    <li>{more} PVDC</li>
    <li>{more} ALU- FOIL</li>
    <li>{more} ALU- TUBES </li>
    <li>{more} ALU-ALU</li>
</ul>
</FadeInSection>
</div>
<div className="col-md-6">
<FadeInSection id="5">
<ul style={{listStyle:'none'}}>
    <li>{more} CRC-CAPS, CAPPS, DROPERS, MEASURING CUP</li>
    <li>{more} GLASS BOTTLES </li>
    <li>{more} PET BOTTLES </li>
    <li>{more} API</li>
    <li>{more} EXCIPIENTS</li>
</ul>
</FadeInSection>
</div>

</div>

        </div> 
        <div className="container-fluid homebg2 testimonial" style={{background:`url(${banner4})`}}>
<h2 style={{textAlign:'center'}}>KEY PARTNERS</h2>
   
<Carousel>
    <Carousel.Item>
    
    <div className="testi">
        <p style={{textAlign:'left',color:'#fff',marginLeft:'13%',lineHeight:'20px',fontWeight:'bold'}}>Name <br/> Designation</p>
       
    
    <p style={{textAlign:'left',marginLeft:'13%'}}>Emad Trade House is a successful, loyal, and trustworthy  business partner of Constantia Flexibles Pharma <br/>
    in the GCC region for more than 10 years. Mr. Muhammad Shabbir and his son Emad have an outstanding network <br/>
   in the region and an excellent understanding of the market and it needs. Together with the well organized and <br/>
   trained customer service team in Dubai and Karachi, Emad Trade House is THE ideal partner for Constantia Flexibles Pharma in this region</p>
    </div>
    </Carousel.Item>
    <Carousel.Item>
    
    <div className="testi">
        <p style={{textAlign:'left',color:'#fff',marginLeft:'13%',lineHeight:'20px',fontWeight:'bold'}}>Name <br/> Designation</p>
       
    
    <p style={{textAlign:'left',marginLeft:'13%'}}>Emad Trade House is a successful, loyal, and trustworthy  business partner of Constantia Flexibles Pharma <br/>
    in the GCC region for more than 10 years. Mr. Muhammad Shabbir and his son Emad have an outstanding network <br/>
   in the region and an excellent understanding of the market and it needs. Together with the well organized and <br/>
   trained customer service team in Dubai and Karachi, Emad Trade House is THE ideal partner for Constantia Flexibles Pharma in this region</p>
    </div>
    </Carousel.Item>
    <Carousel.Item>
    
    <div className="testi">
        <p style={{textAlign:'left',color:'#fff',marginLeft:'13%',lineHeight:'20px',fontWeight:'bold'}}>Name <br/> Designation</p>
       
    
    <p style={{textAlign:'left',marginLeft:'13%'}}>Emad Trade House is a successful, loyal, and trustworthy  business partner of Constantia Flexibles Pharma <br/>
    in the GCC region for more than 10 years. Mr. Muhammad Shabbir and his son Emad have an outstanding network <br/>
   in the region and an excellent understanding of the market and it needs. Together with the well organized and <br/>
   trained customer service team in Dubai and Karachi, Emad Trade House is THE ideal partner for Constantia Flexibles Pharma in this region</p>
    </div>
    </Carousel.Item>
    
    </Carousel>
</div>
        </section>
  )
}
