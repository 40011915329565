import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import banner1 from '../../img/banners/5.jfif'
import banner2 from '../../img/slider/2.jfif'
import banner3 from '../../img/1.jfif'
import banner4 from '../../img/banner4.jfif'
// import slider1 from '../../img/slider/1.jfif'
// import slider2 from '../../img/slider/2.jfif'
 import slider3 from '../../img/slider/1.jfif'
 import slider4 from '../../img/slider/4.jfif'
import slider1 from '../../img/banners/6.jfif'
import slider2 from '../../img/banners/10.jfif'
//import slider3 from '../../img/banners/8.jfif'
//import slider4 from '../../img/banners/9.jfif'
import slider5 from '../../img/slider/6.jfif'
import restauratns from '../../img/partners/restauratns.jpg'
import logistics from '../../img/partners/logistics.jpg'
import onlineTrading from '../../img/partners/onlineTrading.jpg'
import tourism from '../../img/partners/tourism.jpg'
import support from '../../img/partners/support.jpg'
import flight from '../../img/partners/flight.jpg'
import { Carousel } from 'react-bootstrap'
import '../../css/Custom.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlay } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const more = <FontAwesomeIcon icon={faPlay} style={{fontSize:'10px'}} />
export default function Home() {
  function FadeInSection2(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section2 ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }
  return (
    <>
    <Helmet>
        <title>MenaPac| Home</title>
      </Helmet>
    <Carousel>
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider"   
        src={slider1}
        alt="First slide"
       
        />
        <Carousel.Caption className={"cap"}>
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider"
        src={slider2}
        alt="Second slide"
      
        />
        <Carousel.Caption>
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider" 
        src={slider4}
        alt="Second slide"
    
        />
        <Carousel.Caption>
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
        className="d-block w-100 homeslider"
        src={slider3}
        alt="Second slide"
       
        />
        <Carousel.Caption>
        <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
    </Carousel.Item>
   
</Carousel>


<div className="container help mt-5 maincontent" style={{display:'none1'}}>

<div className='row'>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${restauratns})`  }}> 
<p className="partnerhead">Restaurants</p>
</div>

<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${logistics})` }}>
  <a href="https://timexpress.ae" target="_blank" style={{textDecoration:'none'}}>
<p className="partnerhead">Shipping & Logistics</p> </a>
</div>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${onlineTrading})`}}>
<p className="partnerhead">Online Trading</p> 
</div>
</div>
<div className='row'>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${support})`  }}>
<p className="partnerhead">Support & Maintenance Trading</p> 
     </div>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${tourism})` }}>
<p className="partnerhead">Travel & Tourism</p> 
</div>
<div className="col-md-4 partnerbox" style={{ backgroundImage: `url(${flight})`}}>
<p className="partnerhead">Flight Support</p> 
</div>
</div>

</div>

<div className="container help mt-5 maincontent" style={{display:'none1'}}>
{/* <FadeInSection2 key='1' >  */}
<div className='row'>
 
  
<div className="col-md-6 greybg" >
      <h2>WHO WE ARE ?</h2>
      <p style={{marginTop:'6%',textAlign:'left'}}>MENAPAC is a leading distributor of pharmaceutical packaging and equipments. We have been providing top-quality products, custom-tailored medical packaging solutions, and outstanding service to customers worldwide. At MENAPAC, we are proud to meet the needs of medication packagers in a variety of market segments, from independent pharmacies to long-term care facilities, hospitals, and many others.</p>
      <Link to="/about-us" style={{color:'#4bb4f7',textAlign:'left',textDecoration: 'none'}}>Read More &nbsp; {more}</Link>
    </div>
    <div className="col-md-6" style={{paddingLeft:'0px'}}>
    {/* <div className="video-responsive">
    <iframe width="460" height="315" src="https://www.youtube.com/embed/lwQ4bDteBFw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
   </div> */}
   <img src={banner1}  style={{width:'100%'}}/>

    </div>

</div>
{/* </FadeInSection2> */}


</div>


</>

  )
}
