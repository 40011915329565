import React from 'react'
import { Helmet } from 'react-helmet'
import '../../css/Custom.css'
import banner from '../../img/banners/18.jfif'
export default function Formulation() {
  return (
    <section id="cd-intro">
    <Helmet>
            <title>MenaPac | Formulation</title>
          </Helmet>
            <div id="cd-intro-background" className="your_business_page help-page-banner fw" style={{ backgroundImage: `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover',boxShadow: 'inset 0 0 0 50vw #0000004d',backgroundPosition:'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center">
                            <div className="business_content">
                           
                                <h1 className="bannertitle" >Formulation</h1>
                                {/* <p>Search for answers or explore regular help topics below</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="pages_content improvelive" style={{marginBottom:'3%'}}>
            <div className="container">
            <div className="row">
            <div id="primary" className="content-area" style={{float: 'left', width: '100%',marginTop:'2%'}}>
            <main id="main" className="site-main" role="main">
            <div className="entry-content">
              <div className="become_driver im_lives">
                <div className="border_text">
                 
<p>Medicines are changing. In laboratories across AstraZeneca, we aim to target any novel biology we uncover. To do this, we are developing an array of new modalities.
</p>
<p>These potential new therapies are different from the traditional small molecules and current large molecules which are often aimed at targets on the cell surface or delivered without a specific molecular-targeting strategy. As a result, we need advanced drug delivery systems for targeted and controlled release of our novel molecules in tissues and cells in an attempt to optimise their potential benefits for patients.</p>
<p>Our biologists are advancing understanding of cellular transport mechanisms while our chemists, mathematicians and pharmaceutical scientists are using those insights to optimise the design, properties, analysis, formulation and delivery of our potential new medicines.</p>
  </div>
  </div>

  
  </div>
            </main>
            </div>
            </div>
            </div>
            </div>
    </section>
  )
}
