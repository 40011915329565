import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import ScrollToTop from './components/pages/ScrollToTop';
import Home from './components/pages/Home';
import Header from './components/Header';
import Sonoco from './components/pages/Sonoco';
import About from './components/pages/About';
import Footer from './components/Footer';
import PVC from './components/pages/PVC';
import PVDC from './components/pages/PVDC';
import Aluminium from './components/pages/Aluminium';
import Caps from './components/pages/Caps';
import Medical_service from './components/pages/Medical_service';
import Contact from './components/pages/Contact';
import Home1 from './components/pages/Home1';
import API from './components/pages/API';
import Aboutus from './components/pages/Aboutus';
import Sourcing from './components/pages/Sourcing';
import Formulation from './components/pages/Formulation';
import Shipping from './components/pages/Shipping';


function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
      <ScrollToTop/>
      <Header />
      <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/home1" element={<Home1/>}></Route>
      <Route path="/sonoco" element={<Sonoco/>}></Route>
      <Route path="/about-us" element={<Aboutus/>}></Route>
      <Route path="/products/pvc" element={<PVC/>}></Route>
      <Route path="/products/pvdc" element={<PVDC/>}></Route>
      <Route path="/products/aluminium" element={<Aluminium/>}></Route>
      <Route path="/products/caps" element={<Caps/>}></Route>
      <Route path="/products/api" element={<API/>}></Route>
      <Route path="/services/medical" element={<Medical_service/>}></Route>
      <Route path="/contact-us" element={<Contact/>}></Route>
      <Route path="/services/sourcing" element={<Sourcing/>}></Route>
      <Route path="/services/formulation" element={<Formulation/>}></Route>
      <Route path="/services/shipping-and-logistics" element={<Shipping/>}></Route>

      <Route path="/*" element={<Home/>}></Route>
      
      
      </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
